<template>
  <svg :width="size" :height="size" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 5H2V10C2 10.5523 2.44772 11 3 11H9C9.55228 11 10 10.5523 10 10V5H9V4C9 2.34315 7.65685 1 6 1C4.34315 1 3 2.34315 3 4V5ZM8 4V5H4V4C4 2.89543 4.89543 2 6 2C7.10457 2 8 2.89543 8 4Z"
      fill="#C4C6CF"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockFilledIcon',
  props: {
    size: { type: Number, required: false, default: 14 },
  },
};
</script>
