<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8V10H8V8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM18 10H20V12V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V12V10H6V8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8V10ZM18 20V12H6V20H18ZM11 18V14H13V18H11Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'LockIcon',
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
