<template>
  <svg :width="size" :height="size" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 4H0V9C0 9.55229 0.447715 10 1 10H7C7.55228 10 8 9.55229 8 9V4H7V3C7 1.34315 5.65685 0 4 0C2.34315 0 1 1.34315 1 3V4ZM6 3V4H2V3C2 1.89543 2.89543 1 4 1C5.10457 1 6 1.89543 6 3Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 12 },
    fill: { type: String, required: false, default: '#C4C6CF' },
  },
};
</script>
